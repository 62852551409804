<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {fetchPaymentStatusList} from "@/api/common";
import Multiselect from "vue-multiselect";
import {
  required,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  page: {
    title: "Facture Prestataire",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { vueDropzone: vue2Dropzone, Layout, PageHeader , Multiselect},
  import : {fetchPaymentStatusList},
  validations: {
    paymentData: {
      date: {required},
      ovNo: {
        required: requiredIf(function() {
          return this.paymentData.status.id == 'approved'
        }),
      },
      status: {required},      
  },
  },
  data() {
    return {
      submitted: false,
      dropzoneOptions: {
        url: process.env.VUE_APP_BASE_URL + "system/upload/processFileUpload",
        params: {
          modalType: "",
          modelId: "",
        },
        thumbnailWidth: 150,
        maxFilesize: 10,
        maxFiles: 1,
        // autoProcessQueue : false,
        addRemoveLinks: true,
        acceptedFiles: ".pdf,.doc,.docx,.xls,.xlsx",
      },
      title: "Facture N°: ",
      invoiceData: {
        ref: "",
        status: {
          plain: "",
          rendered: "",
        },
        attachmentsList: [],
      },
      attachments: [],
      amounts: {
        invoiceAmnt : "",
        selectedAmt: "",
        diffAmnt: "",
        diffClass: "success"
      },
      paymentStatusList: [],
      ovFile: false,
      invoiceAttachment: false,
      selectedAttachments: [],
      modelId: "",
      modelType: "",
      comments: "",
      showSingleServiceDetailsModal: false,
      showAssignAttachmentsModal: false,
      singleServiceDetails: {
        odsList: [],
        service: "",
      },
      cancelModal: {
        comment: "",
      },
      paymentData: {
        date: "",
        ovNo: "",
        status: "",
        comments: "",
        paidBy: "EBS ADMIN",
        uploadedFileData: {
          fileId: "",
        },
      },
      events: {
        showApprovalModal: false,
        showRejectionModal: false,
        showInvoiceCancelModal: false,
        showNewPaymentModal: false,
        showArchiveModak: false,
        showRegisterPaymentModal: false,
      },
      bloquageData: {
        comments: "",
      },
      items: [
        {
          text: "Suivi & Gestion",
          to: {name: 'monitoring_and_management'},
        },
        {
          text: "Factures prestataires",
          to: {name: 'monitoring_and_management.suppliers_invoices'},
        },
        {
          text: "",
          active: true,
        },
      ],
    };
  },
  computed: {
    amountsAreOkay(){
      if(this.invoiceData.id != undefined){
        if(!this.invoiceData.isRestitutionInvoice) {
          return this.invoiceData.sumAttachments.plain == this.invoiceData.total_amnt.plain;
        }
      }
      return true;
    }
  },

  mounted() {
    this.getInvoiceDetails();
    this._refreshDisplayedAmnt();
  },

  methods: {

    addAttachmentToInvoice(){
      if(this._selectedAttachmentsAreCompliant()){
        var loader = this.$loading.show();
        this.$http
        .post("/supplier_invoicing/addAttachmentToInvoice", {
          invId: this.invoiceData.uuid,
          attachments: this.selectedAttachments,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.showAssignAttachmentsModal = false;
              this.getInvoiceDetails();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
      }
    },

    _format(amnt){
      return  new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(amnt) ;
    },

    _computeSelectedAmntFromAttachments(){
      var sum = 0;
      this.selectedAttachments.forEach(element => {
        sum += parseFloat(element.amnt);
      });
      return sum;
    },

    _refreshDisplayedAmnt(){
      var invAmnt = this.invoiceData ? this.invoiceData.total_amnt.plain : 0;
      var selectedAmnt = this._computeSelectedAmntFromAttachments();
      var diff = invAmnt - selectedAmnt;
      if(diff != 0){
        this.amounts.diffClass = 'danger'
      }else{
        this.amounts.diffClass = 'success'
      }
      this.amounts.invoiceAmnt = this._format(invAmnt);
      this.amounts.selectedAmt = this._format(selectedAmnt);
      this.amounts.diffAmnt = this._format(diff);
    },

    handleSingleAttachmentClick(attachment){
      var attachmentId = attachment.id;
      var attachmentAmnt = parseFloat(attachment.total_amnt.plain);
      var i = 0;
      // remove if already exists
      if(this.selectedAttachments.filter(e => e.id == attachmentId).length > 0){
        this.selectedAttachments.forEach(element => {
          if(element.id == attachmentId){
            this.selectedAttachments.splice(i, 1);
            i++;
          }
        });
      }else{
        var attach = {
          id: attachmentId,
          amnt: attachmentAmnt,
        };
        this.selectedAttachments.push(attach);
      }
      this._refreshDisplayedAmnt();
    },
    loadAvailableAttachments(){
      // attachments that are approved, and not been invoiced
      var loader = this.$loading.show();
        this.$http
          .post("/supplier_invoicing/getAvailableAttachments", {
            contractId: this.invoiceData.contract.uuid,
          })
          .then((res) => {
            loader.hide();
            var msg = res.data.msg;
            var status = res.data.status;
            var invoices = res.data.invoices;
            switch (status) {
                case 200:
                    this.attachments = invoices;
                break;
            
                case 500:
                    this.$toast.warning(msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.warning(error.message);
            // error.response.status Check status code
          })
          .finally(() => {
            loader.hide();
            //Perform action in always
          });
    },

    _selectedAttachmentsAmountsAreValid(){
      var invAmnt = this.invoiceData.total_amnt.plain;
      var selectedAmnt = this._computeSelectedAmntFromAttachments();
      var diff = invAmnt - selectedAmnt;
      return diff == 0
    },

    _selectedAttachmentsAreCompliant(){
      var valid = true;
      // check if we have at least one selected attachment
      if(this.selectedAttachments.length == 0){
        valid = false;
        this.$toast.error("Vous devez selectionner au moin un attachement pour pouvoir continuer");
      }
      // recheck amounts again
      if(!this._selectedAttachmentsAmountsAreValid()){
        valid = false;
        this.$toast.error("La somme des attachement n'est pas conforme avec la somme de la facture");
      }
      return valid;
    },

    approve() {
      this.dropzoneOptions.params.attachmentType = "supplier_invoice";
      this.events.showApprovalModal = true;
    },

    approveInvoice() {
      var loader = this.$loading.show();
      this.$http
        .post("/ops/decisions/approve", {
          modelId: this.modelId,
          modelType: this.modelType,
          comments: this.comments,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.events.showApprovalModal = false;
              this.getInvoiceDetails();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    showRejectionModal() {
      this.events.showRejectionModal = true;
    },
    showInvoiceCancelModal(){
      this.events.showInvoiceCancelModal = true;
    },
    rejectInvoice() {
      Swal.fire({
        title:
          "Êtes-vous sûr de bloquer la facture N°: " +
          this.invoiceData.ref +
          " ?",
        text: "Cette action est irreversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/supplier_invoicing/changeInvoiceStatus", {
              invoiceId: this.invoiceData.uuid,
              op: "set_to_stand_by",
              comment: this.bloquageData.comments,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.events.showRejectionModal = false;
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    cancelInvoice(){
      Swal.fire({
        title:
          "Êtes-vous sûr d'annuler la facture N°: " +
          this.invoiceData.ref +
          " ?",
        text: "Cette action est irreversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/supplier_invoicing/changeInvoiceStatus", {
              invoiceId: this.invoiceData.uuid,
              op: "set_to_cancel",
              comment: this.cancelModal.comment,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.events.showInvoiceCancelModal = false;
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    getPaymentStatusList(){
      var loader = this.$loading.show();
      fetchPaymentStatusList()
      .then((res) => {
          loader.hide();
          this.paymentStatusList = res.data.original.data;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    async registerPaymentClick() {
      await this.getPaymentStatusList();
      this.events.showRegisterPaymentModal = true;
      this.dropzoneOptions.params.attachmentType = "supplier_invoice_payment";
    },

    editPaymentClick(){
      // get Payment details
      this.getPaymentStatusList();
      var loader = this.$loading.show();
      this.$http
        .post("/invoicing/getSinglePaymentDetails", {
          paymentId: this.invoiceData.payment.uuid,
        })

        .then((res) => {
          loader.hide();
          var status = res.data.original.status;
          var payment = res.data.original.payment;
          switch (status) {
            case 200:
              this.paymentData.date = payment.date;
              this.paymentData.ovNo = payment.ovNo;
              this.paymentData.status = payment.status;
              this.paymentData.comments = payment.comments;
              this.paymentData.status = payment.status_obj;
              this.paymentData.uid = payment.uuid;
              break;

            case 500:
              this.$toast.warning(res.data.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
      this.events.showRegisterPaymentModal = true;
      this.dropzoneOptions.params.attachmentType = "supplier_invoice_payment";
    },

    registerInvoicePayment() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.paymentData.$touch();
      if(!this.$v.paymentData.$invalid){
        var loader = this.$loading.show();
        this.$http
          .post("/supplier_invoicing/registerInvoicePayment", {
            invoiceId: this.invoiceData.uuid,
            paymentData: this.paymentData,
          })
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.events.showRegisterPaymentModal = false;
                this.dropzoneOptions.params.attachmentType = null;
                this.getInvoiceDetails();
                break;
  
              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
  
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    unblockInvoice(){
        Swal.fire({
        title:
          "Êtes-vous sûr De Débloquer la facture N°: " +
          this.invoiceData.ref +
          " ?",
        text: "Cette action est irreversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/supplier_invoicing/changeInvoiceStatus", {
              invoiceId: this.invoiceData.uuid,
              op: "unset_stand_by",
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    archive() {
      Swal.fire({
        title:
          "Êtes-vous sûr De Clôturer la facture N°: " +
          this.invoiceData.ref +
          " ?",
        text: "Cette action est irreversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/supplier_invoicing/changeInvoiceStatus", {
              invoiceId: this.invoiceData.uuid,
              op: "set_to_close",
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    setDraft() {
      Swal.fire({
        title:
          "Êtes-vous sûr De remettre la facture N°: " +
          this.invoiceData.ref +
          " en brouillon ?",
        text: "Cette action est irreversible",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/supplier_invoicing/changeInvoiceStatus", {
              invoiceId: this.invoiceData.uuid,
              op: "set_to_draft",
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    
    getInvoiceDetails() {
      var loader = this.$loading.show();
      this.$http
        .post("/supplier_invoicing/getInvoiceDetails", {
          invoiceId: this.$route.params.uid,
        })

        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var invoice = res.data.invoice;
          switch (status) {
            case 200:
              this.invoiceData = invoice;
              this.modelId = invoice.id;
              this.title = "Facture N°: ";
              this.title = this.title + '# '+invoice.ref;
              this.items[this.items.length - 1].text = this.title;
              this.modelType = invoice.modelType;
              this.invoiceData.attachmentsList = invoice.attachements;
              // set data to DZ params
              this.dropzoneOptions.params.modalType = invoice.modelType;
              this.dropzoneOptions.params.modalId = invoice.id;

              //   console.log(this.invoiceData.);
              break;

            case 500:
              this.$toast.warning(res.data.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();

          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    afterComplete(file) {
      var res = JSON.parse(file.xhr.response);
      this.paymentData.uploadedFileData.fileId = res.original.uploadedFile.uuid;
    },

    addAttachmentsClick(){
      this.loadAvailableAttachments();
      this.showAssignAttachmentsModal = true;
    },

    handleCloseAssignAttachmentsModalEvent(){
      this.showAssignAttachmentsModal = false;
    },

    detachSingleAttachmentFromInvoice(attachment){
      Swal.fire({
        title:
          "Êtes-vous sûr De détacher l'attachement N°: " + attachment.ref +" de la facture N°: "+ this.invoiceData.ref +" ?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Continuer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();

          this.$http
            .post("/supplier_invoicing/detachSingleAttachmentFromInvoice", {
              atachmentId: attachment.uuid,
              invId: this.invoiceData.uuid
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.getInvoiceDetails();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <fieldset :disabled="!amountsAreOkay || invoiceData.status.plain == 'closed'">
              <div class="invoice-title">
              <h4 class="float-right font-size-16" @click="getInvoiceDetails">
                Facture N° # <b>{{ invoiceData.ref }}</b>
                <span v-html="invoiceData.status.rendered"></span>
                <span v-html="invoiceData.standby_html"></span>
              </h4>
              <div class="mb-4">
                <div class="button-items">
                  <b-button
                    @click.prevent="approve"
                    :disabled="invoiceData.status.plain != 'draft' || invoiceData.standby"
                    variant="outline-success"
                  >
                    <i class="fas fa-check"></i> Approuver</b-button
                  >

                  <b-button
                    @click.prevent="showInvoiceCancelModal"
                    :disabled="invoiceData.standby"
                    variant="outline-danger"
                  >
                    <!-- v-if="invoiceData.status.plain == 'draft'" -->
                    <i class="fas fa-window-close"></i> Annuler</b-button
                  >

                  <b-button
                    @click.prevent="showRejectionModal"
                    :disabled="invoiceData.standby"
                    variant="outline-warning"
                  >
                    <i class="fas fa-window-close"></i> Bloquer</b-button
                  >
                  <b-button
                    @click.prevent="unblockInvoice"
                    :disabled="!invoiceData.standby"
                    variant="outline-success"
                  >
                    <i class="fas fa-window-close"></i> Débloquer</b-button
                  >
                  <b-button
                    @click.prevent="registerPaymentClick"
                    :disabled="invoiceData.status.plain != 'approved' || invoiceData.payment != null || invoiceData.standby"
                    variant="outline-info"
                  >
                    <i class="fas fa-money-bill"></i> Enregistrer un
                    paiement</b-button
                  >
                  <b-button
                    @click.prevent="archive"
                    :disabled="invoiceData.status.plain != 'approved' || invoiceData.standby"
                    variant="outline-warning"
                  >
                    <i class="fas fa-archive"></i> Clôturer</b-button
                  >
                  <b-button
                    @click.prevent="setDraft"
                    :disabled="invoiceData.status.plain == 'closed' || invoiceData.status.plain == 'draft' || invoiceData.status.plain == 'canceled' || invoiceData.standby" 
                    variant="outline-secondary"
                  >
                    <i class="fas fa-undo"></i> Remettre en brouillon</b-button
                  >
                </div>
              </div>
            </div>
            </fieldset>
            <hr />
            <div class="row">
              <div class="col-3">
                <address>
                  <strong>Facturé à:</strong>
                  <br />SONATRACH <br />Direction de production <br />Direction
                  régionale IRARA <br />Hassi Messoud, Ouergla
                </address>
              </div>
              <div class="col-3">
                <address>
                  <strong>Prestataire:</strong>
                  <br>
                  {{ invoiceData.contract.supplier.prestname }} <br />
                  {{ invoiceData.contract.supplier.adress1 }} <br />
                  {{ invoiceData.contract.supplier.adress2 }}<br />
                  {{ invoiceData.contract.supplier.adress3 }}
                </address>
              </div>
              <div class="col-3">
                <address>
                  <strong>Infos Paiement:</strong><br />
                  <b>Status:</b>
                  <template v-if="invoiceData.payment">
                    <span v-html="invoiceData.payment.status.rendered"></span>
                    <span class="ml-3" v-if="invoiceData.payment.status.plain == 'blocked'">
                      <b>Motif de bloquage: </b>{{ invoiceData.payment.comments }} 
                    </span>
                    <span class="ml-3" v-if="invoiceData.payment.status.plain == 'rejected'">
                      <b>Motif de Rejet: </b>{{ invoiceData.payment.comments }} 
                    </span>
                    <br />
                  </template>
                  
                  <b>Ref:</b>
                  <span class="text-primary" @click="editPaymentClick">
                    #{{ invoiceData.payment ? invoiceData.payment.ref : "N/A" }}
                    <template v-if="invoiceData.payment">
                      <i v-if="invoiceData.payment.status.plain == 'draft'" class="fas fa-edit"></i>
                    </template>
                    
                    </span>
                  <br />
                  <b>Date:</b>
                  {{ invoiceData.payment ? invoiceData.payment.date : "N/A" }}
                  <br />
                  <b>N° OV:</b>
                  <span class="text-primary"
                    >#{{
                      invoiceData.payment ? invoiceData.payment.n_ov : "N/A"
                    }}</span
                  >
                  <br />
                  <b>Document Electronique:</b>
                  <a
                    v-if="invoiceData.payment"
                    :href="invoiceData.payment.ov_file.fullPath"
                    target="_blank"
                    >Télécharger <i class="fas fa-download"></i>
                  </a>
                  <span class="text-primary" v-else> N/A</span>
                  <br />
                  <b>Par:</b>
                  <a href="javascript:void(0)">
                    #{{
                      invoiceData.payment ? invoiceData.payment.p_by : "N/A"
                    }}
                  </a>
                </address>
              </div>

              <div class="col-3"></div>
            </div>
            <div class="row">
              <div class="col-3 mt-3">
                <address>
                  <strong>Mode de paiement:</strong>
                  <br />Virement bancaire <br />
                  {{ invoiceData.contract.sp_domiciliation }}
                </address>
              </div>

              <div class="col-3 mt-3">
                <address>
                  <strong>Date de facture:</strong>
                  <br />{{ invoiceData.dates.issueDate }}
                  <br />
                  <br />
                </address>
              </div>
              <div class="col-3 mt-3">
                <address>
                  <strong>Date de réception facture:</strong>
                  <br />{{ invoiceData.dates.receptionDate }}
                  <br />
                  <br />
                </address>
              </div>

              <div v-if="invoiceData.isRestitutionInvoice" class="col-3 mt-3">
                <span class="badge badge-pill badge-soft-success font-size-11"><h5>Restitution de garantie du contrat: <b>{{ invoiceData.contract.ref }}</b></h5></span>
              </div>
            </div>
            <div v-if="!invoiceData.isRestitutionInvoice" class="p-2 mt-3">
              <h3 class="font-size-16">Liste des attachements</h3>
              <a v-if="invoiceData.status.plain == 'draft' && !amountsAreOkay" @click.prevent="addAttachmentsClick" href="" class="font-size-9 float-right"> <i class="fas fa-plus"></i> Ajouter des attachements</a> <br>
              <h3 class="font-size-16 float-right">
                <span class="badge badge-success" v-if="amountsAreOkay">Montants conformes</span>
                <span class="badge badge-danger" v-else>Montants Non conformes</span>
              </h3>
            </div>
            <div class="table-responsive">
              <table v-if="!invoiceData.isRestitutionInvoice" class="table">
                <thead>
                  <tr>
                    <th style="width: 70px;">No.</th>
                    <th>N° Attachement</th>
                    <th>Date de création</th>
                    <th>Créer par</th>
                    <th class="text-right">Sous total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style="font-weight: bold;" v-for="(attachment, index) in invoiceData.attachmentsList" v-bind:key="attachment.id">
                    <td>{{ index + 1 }}</td>
                    <td>
                        <router-link
                        target="_blank"
                        :to="{
                          name: 'invoicing.display',
                          params: { uid: attachment.uuid },
                        }"
                      >
                        {{ attachment.ref }}
                      </router-link>
                        <a v-if="invoiceData.status.plain == 'draft'" @click.prevent="detachSingleAttachmentFromInvoice(attachment)" href=""><i class="fas fa-trash text-danger ml-2"></i></a>
                        </td>
                    <td> {{ attachment.dates.generatedAt }} </td>
                    <td>#</td>
                    <td class="text-right"> {{ attachment.total_amnt.withCurrency }} </td>
                  </tr>
                </tbody>
              </table>

              <table class="table">
                <tbody>
                  <!--  TOTALS -->
                  <tr style="font-weight: bold;">
                    <td colspan="4" class="text-right">Total Attachements</td>
                    <td class="text-right">
                      {{ invoiceData.sumAttachments.withCurrency }}
                    </td>
                  </tr>
                  <tr style="font-weight: bold;">
                    <td colspan="4" class="text-right">Total HT</td>
                    <td class="text-right">
                      {{ invoiceData.untaxed_amnt.withCurrency }}
                    </td>
                  </tr>
                  <tr style="font-weight: bold;">
                    <td colspan="4" class="border-0 text-right">
                      <strong>Garantie</strong>
                    </td>
                    <td class="border-0 text-right">
                      {{ invoiceData.appliedWarranty.withCurrency }}
                    </td>
                  </tr>
                  <tr style="font-weight: bold;">
                    <td colspan="4" class="border-0 text-right">
                      <strong>Pénalités</strong>
                    </td>
                    <td class="border-0 text-right">
                      {{ invoiceData.appliedPenalty.withCurrency }}
                    </td>
                  </tr>
                  <tr style="font-weight: bold;">
                    <td colspan="4" class="border-0 text-right">
                      <strong>Tax</strong>
                    </td>
                    <td class="border-0 text-right">
                      {{ invoiceData.tax_amnt.withCurrency }}
                    </td>
                  </tr>
                  <tr style="font-weight: bold;">
                    <td colspan="4" class="border-0 text-right">
                      <strong>Total TTC</strong>
                    </td>
                    <td class="border-0 text-right">
                      <h2 class="m-0">
                        {{ invoiceData.finalTotal.withCurrency }}
                      </h2>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- MODAL -->

    <!-- Invoice service details modal -->
    <Modal
      v-model="showSingleServiceDetailsModal"
      :title="`Détails des prestations: ${singleServiceDetails.service}`"
      modalClass="fullWidthModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="">
            <div class="">
              <h4 class="card-title"></h4>
              <p class="card-title-desc">
                
              </p>

              <div class="table-responsive">
                <table class="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>N°</th>
                      <th>N° ODS</th>
                      <th>Date de demande</th>
                      <th>Date d'exécution</th>
                      <th>Montant Globale de l'ODS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(ods, index) in singleServiceDetails.odsList" v-bind:key="ods.id">
                      <th scope="row">{{ index + 1 }}</th>
                      <td><span class="text-primary">{{ ods.ref }}</span></td>
                      <td>{{ ods.date_ODS }}</td>
                      <td>{{ ods.date_exec_ODS }}</td>
                      <td><b>{{ ods.totalAmnt.withCurrency }}</b></td>
                    </tr>
                   
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <!-- 1- APPROBATION MODAL -->
    <Modal
      v-model="events.showApprovalModal"
      :title="`Approuver l'attachement N°: ${invoiceData.ref}`"
    >
      <form @submit.prevent="approveInvoice">
        <div class="row">
          <div class="col-md-12">
            <label for="">Facture *</label>
            <vue-dropzone
              id="dropzone"
              v-model="invoiceAttachment"
              class="ebs-upload"
              ref="myVueDropzone"
              :use-custom-slot="true"
              :options="dropzoneOptions"
              @vdropzone-complete="afterComplete"
            >
              <div class="dropzone-custom-content">
                <i class="display-4 text-muted fas fa-upload"></i> <br>
                <h4>Déposez les fichiers ici ou cliquez pour télécharger.</h4>
              </div>
            </vue-dropzone>
          </div>

          <div class="col-md-12">
            <label for="">Commentaires</label>
            <textarea
              name=""
              class="form-control"
              id=""
              cols="30"
              rows="3"
            ></textarea>
          </div>
        </div>
        <br />

        <div>
          <button class="btn btn-primary" type="submit">
            <i class="fas fa-check"></i>
            Approuver
          </button>
        </div>
      </form>
    </Modal>

    <!-- 2- Rejection MODAL -->
    <Modal
      v-model="events.showRejectionModal"
      :title="`Bloquer la facture N°: ${invoiceData.ref}`"
    >
      <form @submit.prevent="rejectInvoice">
        <div class="row">
          <div class="col-md-12">
            <label for="">Raison de bloquage</label>
            <textarea
              name=""
              required
              v-model="bloquageData.comments"
              class="form-control"
              id=""
              cols="30"
              rows="3"
            ></textarea>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-danger float-right" type="submit">
              <i class="fas fa-window-close"></i>
              Bloquer
            </button>
          </div>
        </div>
      </form>
    </Modal>
    <!-- 3- Register Payment MODAL -->
    <Modal
      v-model="events.showRegisterPaymentModal"
      :title="
        `Enregistrer un paiement pour la facture N°: ${invoiceData.ref}`
      "
    >
      <form
        @submit.prevent="registerInvoicePayment"
        enctype="multipart/form-data"
      >
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Status *</label>
              <multiselect
                :searchable="true"
                v-model="paymentData.status"
                track-by="id"
                :select-label="''"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.paymentData.status.$error,
                }"
                label="name"
                :options="paymentStatusList"
                placeholder="Select Payment Status"
                :allow-empty="false"
              >
                <template slot="singleType" slot-scope="{ type }">{{
                  type.name
                }}</template>
              </multiselect>
              <div v-if="submitted && $v.paymentData.status.$error" class="invalid-feedback">
                <span v-if="!$v.paymentData.status.required">Champ Obligatoire.</span>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Date *</label>
              <input
                id="projectname"
                type="date"
                v-model="paymentData.date"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.paymentData.date.$error,
                }"
                class="form-control"
                placeholder=""
              />
              <div v-if="submitted && $v.paymentData.date.$error" class="invalid-feedback">
                <span v-if="!$v.paymentData.date.required">Champ Obligatoire.</span>
              </div>
            </div>
          </div>

          <div class="col-md-4" v-if="paymentData.status.id == 'approved'">
            <div class="form-group">
              <label for="formrow-email-input">N° OV *</label>
              <input
                id="projectname"
                type="text"
                v-model="paymentData.ovNo"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.paymentData.ovNo.$error,
                }"
                class="form-control"
                placeholder=""
              />
              <div v-if="submitted && $v.paymentData.ovNo.$error" class="invalid-feedback">
                <span v-if="!$v.paymentData.ovNo.required">Champ Obligatoire.</span>
              </div>
            </div>
          </div>

          <div class="col-md-12" v-if="paymentData.status.id == 'approved'">
            <label for="formrow-email-input">Document Electronique *</label>
            <vue-dropzone
              id="dropzone"
              v-model="ovFile"
              class="ebs-upload"
              ref="myVueDropzone"
              :use-custom-slot="true"
              :options="dropzoneOptions"
              @vdropzone-complete="afterComplete"
            >
              <div class="dropzone-custom-content">
                <i class="display-4 text-muted fas fa-upload"></i> <br>
                <h4>Déposez les fichiers ici ou cliquez pour télécharger.</h4>
              </div>
            </vue-dropzone>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="formrow-email-input">Commentaires</label>
              <textarea
                name=""
                v-model="paymentData.comments"
                class="form-control"
                id=""
                cols="30"
                rows="3"
              ></textarea>
            </div>
          </div>
        </div>
        <br />

        <div>
          <button class="btn btn-primary" type="submit">
            <i class="fas fa-save"></i>
            Enregistrer
          </button>
        </div>
      </form>
    </Modal>
    <!-- 4- APPROBATION MODAL -->

    <Modal
      v-model="showAssignAttachmentsModal"
      title="Ajouter des attachements"
      modalClass="fullWidthModal"
      @close="handleCloseAssignAttachmentsModalEvent"
    >
      <form>
        <b-alert show variant="info">
          <i class="mdi mdi-alert-circle-outline mr-2"></i>Le système prend en considération que les opérations validés. <br>
        </b-alert>

        <div class="row">
          <div class="col-lg-12">
            <div class="">
              <div class="">
                <h4 class="card-title">
                  Liste des attachements
                  <div class="float-right">
                    <span>TTC Facture: {{ amounts.invoiceAmnt }}</span>
                    <span class="ml-3">Total Attachements: {{ amounts.selectedAmt }}</span>
                    <span class="ml-3" :class="`text-${amounts.diffClass}`">Différence: {{ amounts.diffAmnt }}</span>
                  </div>
                  </h4>
                <div class="table-responsive">
                  <table class="table mb-0">
                    <thead class="thead-light">
                      <tr>
                        <th>ID</th>
                        <th>N° Attachement</th>
                        <th>Date</th>
                        <th>Montant total</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(attachment, index) in attachments" v-bind:key="attachment.id">
                        <th scope="row">
                          <b-form-checkbox
                            :value="index"
                            unchecked-value="not_accepted"
                            @input="handleSingleAttachmentClick(attachment)"
                            class="custom-checkbox-primary mb-3"
                            checked
                          ></b-form-checkbox>
                        </th>
                        <td>
                          <router-link
                            target="_blank"
                            :to="{
                              name: 'invoicing.display',
                              params: { uid: attachment.uuid },
                            }"
                          >
                            {{ attachment.ref }}
                          </router-link>
                          </td>
                        <td>{{ attachment.dates.generatedAt }}</td>
                        <td><b>{{ attachment.total_amnt.withCurrency }}</b></td>
                        <td> <span v-html="attachment.status.rendered"></span> </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button class="btn btn-primary" @click.prevent="addAttachmentToInvoice" type="submit">
            <i class="fas fa-plus"></i>
            Ajouter
          </button>
        </div>
      </form>
    </Modal>

  <!-- Credit note modal -->
    <Modal
      v-model="events.showInvoiceCancelModal"
      :title="`Annuler la facture N°: ${invoiceData.ref}`"
    >
      <form @submit.prevent="cancelInvoice">
        <div class="row">
          <div class="col-md-12">
            <label for="">Raison d'annulation</label>
            <textarea
              name=""
              required
              class="form-control"
              v-model="cancelModal.comment"
              id=""
              cols="30"
              rows="3"
            ></textarea>
          </div>
        </div>
        <br />

        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-danger float-right" type="submit">
              <i class="fas fa-window-close"></i>
              Annuler
            </button>
          </div>
        </div>
      </form>
    </Modal>

  </Layout>
</template>
